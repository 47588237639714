import * as bootstrap from "../../node_modules/bootstrap/dist/js/bootstrap.bundle"
window.bootstrap = bootstrap

import Swiper from "swiper"
import { Navigation, Pagination, Autoplay } from "swiper/modules"

import * as ScrollMagic from "scrollmagic"
import { ScrollMagicPluginIndicator } from "scrollmagic-plugins"
ScrollMagicPluginIndicator(ScrollMagic)

import * as Bouncer from "formbouncerjs"

let controller
let sceneLeft
let sceneRight
let sceneWrapper

/* The initial HTML document has been completely loaded and parsed */
document.addEventListener("DOMContentLoaded", () => {
    // Offcanvas menu
    const offcanvasMenu = document.getElementById("offcanvasMenu")
    offcanvasMenu.querySelectorAll(".nav-link").forEach(item => {
        item.addEventListener("click", () => {
            var bsOffcanvas = bootstrap.Offcanvas.getInstance(
                document.getElementById("offcanvasNavbar")
            )
            bsOffcanvas.hide()
        })
    })

    creatScollMagicScene()

    // Update scene parameters when viewport is resized
    window.addEventListener("resize", function () {
        controller.destroy(true)
        sceneLeft.destroy(true)
        sceneRight.destroy(true)
        sceneWrapper.destroy(true)

        creatScollMagicScene()
    })

    // Hero phone slider
    new Swiper(".phone-slider", {
        modules: [Autoplay],
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        }
    })

    // Hero tablet slider
    new Swiper(".tablet-slider", {
        modules: [Autoplay],
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        }
    })

    // Testemonial
    setTimeout(() => {
        //xs
        new Swiper(".testemonial-slider", {
            modules: [Navigation, Pagination],
            slidesPerView: 1.01,
            spaceBetween: 10,
            loop: true,
            rewind: true,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 4,
            centeredSlidesBounds: true,
            initialSlide: 0,
            centeredSlides: true,
            navigation: {
                nextEl: ".swiper-button-right",
                prevEl: ".swiper-button-left"
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },
            breakpoints: {
                //sm
                361: {
                    slidesOffsetBefore: 3,
                    slidesOffsetAfter: 5,
                    spaceBetween: 50,
                    slidesPerView: 1.2,
                    centeredSlides: true,
                    loop: true
                },
                //md
                559: {
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                    slidesPerView: 1.1,
                    spaceBetween: 90,
                    centeredSlides: true,
                    centeredSlidesBounds: false,
                    loop: false
                },
                //lg
                769: {
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 10,
                    loop: true,
                    slidesPerView: "auto",
                    spaceBetween: 95,
                    centeredSlides: true
                },
                //xl
                1025: {
                    slidesOffsetBefore: 5,
                    slidesOffsetAfter: 5,
                    slidesPerView: "auto",
                    spaceBetween: 32
                },
                //xxl
                1441: {
                    slidesOffsetBefore: 10,
                    slidesOffsetAfter: 10,
                    slidesPerView: "auto",
                    spaceBetween: 70
                }
            }
        })
    }, 50)

    const testemonialModal = document.getElementById("testemonialModal")
    if (testemonialModal) {
        testemonialModal.addEventListener("show.bs.modal", event => {
            const button = event.relatedTarget

            const name = button.getAttribute("data-bs-name")
            const title = button.getAttribute("data-bs-title")
            const review = button.getAttribute("data-bs-review")

            const modalName = testemonialModal.querySelector(".questionModalLabel")
            const modalTitle = testemonialModal.querySelector(".questionModalTitle")
            const modalReview = testemonialModal.querySelector(".questionModalReview")

            modalName.textContent = `${name}`
            modalTitle.innerHTML = `${title}`
            modalReview.innerHTML = `${review}`
        })
    }

    formSubmit()

    function setEqualHeight(container) {
        const rows = container.querySelectorAll(".row")

        rows.forEach(row => {
            const cards = row.querySelectorAll(".card")
            let maxHeight = 0

            // Reset the height to auto to get the natural height of each card
            cards.forEach(card => {
                card.style.height = "auto"
            })

            // Calculate the maximum height
            cards.forEach(card => {
                const cardHeight = card.offsetHeight
                if (cardHeight > maxHeight) {
                    maxHeight = cardHeight
                }
            })

            // Set the height of each card to the maximum height
            cards.forEach(card => {
                card.style.height = `${maxHeight}px`
            })
        })
    }

    function checkAndSetHeight() {
        const sectionServices = document.getElementById("section-services")
        if (window.innerWidth >= 768) {
            setEqualHeight(sectionServices)
        } else {
            // Reset card heights when the screen width is below 768px
            const cards = sectionServices.querySelectorAll(".card")
            cards.forEach(card => {
                card.style.height = "auto"
            })
        }
    }
    setTimeout(() => {
        checkAndSetHeight()
    }, 150)
    // Recalculate heights on window resize
    window.addEventListener("resize", checkAndSetHeight)
})

/**
 * The whole page has been completely loaded, including
 * all dependent resources (stylesheets and images)
 */
window.addEventListener("load", () => {})

function creatScollMagicScene() {
    controller = new ScrollMagic.Controller()

    // Scene for #bg_right element
    sceneRight = new ScrollMagic.Scene({
        triggerElement: ".section-hero",
        duration: distanceFromBottomLine("#section-hero"),
        offset: -72,
        triggerHook: 0
    })
        .setPin("#bg_right")
        .addTo(controller)

    sceneRight.on("progress", function (event) {
        const scrollProgress = event.progress // Progress of the scene (from 0 to 1)
        const rotationDegree = 30 * (1 - scrollProgress)
        const element = document.getElementById("bg_right")
        element.style.transform = "rotate(" + rotationDegree + "deg)"
        element.style.transformOrigin = "top left"
    })

    // Scene for #bg_left element
    sceneLeft = new ScrollMagic.Scene({
        triggerElement: ".section-hero",
        duration: distanceFromBottomLine("#section-hero") - 100,
        offset: -72,
        triggerHook: 0
    }).addTo(controller)

    sceneLeft.on("progress", function (event) {
        const scrollProgress = event.progress // Progress of the scene (from 0 to 1)
        const element = document.getElementById("bg_left")
        const opacity = 1 - scrollProgress
        const translationY = 300 * scrollProgress
        const translationX = 30 * scrollProgress
        element.style.opacity = opacity
        element.style.transform =
            "translate(" +
            translationX +
            "px, " +
            translationY +
            "px) rotate(" +
            30 * (1 - scrollProgress) +
            "deg)"
        element.style.transformOrigin = "top left"
    })

    // Scene for #scene-wrapper element
    sceneWrapper = new ScrollMagic.Scene({
        triggerElement: ".section-hero",
        duration: distanceFromBottomLine("#section-hero") - 80,
        offset: -72,
        triggerHook: 0
    })
        .setPin(".scene-wrapper")
        .addTo(controller)

    sceneWrapper.on("progress", function (event) {
        const scrollProgress = event.progress // Progress of the scene (from 0 to 1)
        const element = document.getElementById("scene-wrapper")
        const translationY = -30 * scrollProgress
        const translationX = -100 * scrollProgress

        if (window.innerWidth > 1024) {
            // Apply rotation only if window width is >= 1024 pixels
            element.style.transform =
                "translate(" +
                translationX +
                "px, " +
                translationY +
                "px) rotate(" +
                30 * (1 - scrollProgress) +
                "deg)"
        } else if (window.innerWidth >= 900) {
            element.style.transform = "translate(" + -80 + "px, " + 20 + "px)"
        } else if (window.innerWidth >= 800) {
            element.style.transform =
                "translate(" + -85 + "px, " + distanceFromBottomLine(".phone-element") / 2.5 + "px)"
        }
    })

    // Scene for #phone-wrapper element
    sceneWrapper.on("progress", function (event) {
        const scrollProgress = event.progress // Progress of the scene (from 0 to 1)
        const element = document.querySelector(".phone-element")
        const translationY = 200 * scrollProgress
        if (window.innerWidth > 1024) {
            element.style.transform = "translate(0, " + translationY + "px)"
            element.style.transformOrigin = "top right"
        } else {
            element.style.transform = "translate(0, " + translationY + "px)"
            element.style.transformOrigin = "bottom right"
        }
    })

    // Scene for glasses element
    sceneWrapper.on("progress", function (event) {
        const scrollProgress = event.progress // Progress of the scene (from 0 to 1)
        const element = document.querySelector(".glasses-element")
        if (scrollProgress > 0.1) {
            element.style.opacity = 0
        } else {
            element.style.opacity = 1
        }
    })
}

function distanceFromBottomLine(sectionSelector) {
    const section = document.querySelector(sectionSelector)

    if (!section) {
        return null
    }

    const sectionHeight = section.offsetHeight
    const sectionTop = section.offsetTop
    const bottomLineDistance = sectionTop + sectionHeight

    return bottomLineDistance
}

function formSubmit() {
    new Bouncer("form", {
        disableSubmit: true,
        patterns: {
            email: /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$/,
            url: /^(?:(?:https?|HTTPS?|ftp|FTP):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/,
            number: /[-+]?[0-9]*[.,]?[0-9]+/,
            color: /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/,
            date: /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))/,
            time: /(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9])/,
            month: /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2]))/
        },
        // Error messages by error type
        messages: {
            missingValue: {
                checkbox: "Для продолжения необходимо ваше согласние.",
                radio: "Пожалуйста, выберите значение.",
                select: "Пожалуйста, выберите значение.",
                "select-multiple": "Пожалуйста, выберите хотя бы одно значение.",
                default: "Пожалуйста, заполните это поле."
            },
            patternMismatch: {
                email: "Пожалуйста, введите правильный адрес электронной почты.",
                url: "Пожалуйста, введите URL.",
                number: "Пожалуйста, введите число.",
                color: "Пожалуйста, укажите цвет в формате: #rrggbb",
                date: "Пожалуйста, используйте формат YYYY-MM-DD",
                time: "Пожалуйста, используйте 24-часовой формат времени. Например, 23:00",
                month: "Пожалуйста, используйте формат YYYY-MM",
                default: "Пожалуйста, соответствуйте запрошенному формату."
            },
            outOfRange: {
                over: "Пожалуйста, выберите значение, которое не превышает {max}.",
                under: "Пожалуйста, выберите значение, которое не меньше {min}."
            },
            wrongLength: {
                over: "Пожалуйста, сократите этот текст до {maxLength} символов. Вы используете {length} символов.",
                under: "Пожалуйста, увеличьте этот текст до {minLength} символов или более. Вы используете {length} символов."
            }
        }
    })

    // Detect a successful form validation
    document.addEventListener(
        "bouncerFormValid",
        function () {
            // The successfully validated form
            const form = document.getElementById("formQuestion")
            form.querySelector('button[type="submit"]').innerHTML = "Пожалуйста, подождите..."
            form.querySelector('button[type="submit"]').disabled = true

            document.getElementById("response").style.display = "block"

            const payload = {
                name: form.querySelector('input[name="name"]').value,
                email: form.querySelector('input[name="email"]').value,
                message: form.querySelector('textarea[name="message"]').value,
                timestampHHMMSS: new Date().toLocaleTimeString(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }

            const url =
                "https://n8n--tg74u9.humancheck.ru/webhook/e5d9c7e1-5561-42bc-b48f-2d5eaad0cafa"

            fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            })
                .then(res => res.json())
                .then(data => {
                    form.querySelector('input[name="terms"]').checked = false
                    form.querySelector('button[type="submit"]').disabled = false
                    form.querySelector('button[type="submit"]').innerHTML = "Отправить"
                    if (data.response === "success") {
                        document.getElementById("response-text").innerHTML = data.response_message
                        form.reset()
                    }
                })
                .catch(() => {
                    form.querySelector('button[type="submit"]').disabled = false
                    form.querySelector('button[type="submit"]').innerHTML = "Отправить"
                    document.getElementById("response-text").innerHTML =
                        "Возникла непредвиденная ошибка. Пожалуйста, напишите ваш запрос на <a href='mailto:info@humancheck.ru'>info@humancheck.ru</a>"
                })
        },
        false
    )
}
